<template>
  <b-container fluid class="">
    <b-row v-for="(hourData, index) in internalAgendaHours" :key="hourData.id">
      <div class="agenda-hours-box" @click="showHourData(index)">
        <div class="agenda-time-section">
          <p class="agenda-time-data m-0">{{ moment(hourData.start_time) }}</p>
          <i v-if="!isMobileScreen" class="fas fa-angle-down agenda-time-data" />
          <span v-if="isMobileScreen" class="pt-3">-</span>
          <p class="agenda-time-data m-0">{{ moment(hourData.end_time) }}</p>
        </div>
        <div
          v-if="hourData.type === 'static'"
          class="pl-2"
          :class="!$lodash.isEmpty(hourData.labels) ? 'agenda-title-section-50' : 'agenda-title-section-65'"
        >
            <p class="agenda-hours-title-text">{{hourData.static_details.default_entries_details.name}}</p>
          <p class="agenda-hours-sub-text">{{ hourData.static_details.custom_text }}</p>
        </div>
        <div
          v-if="hourData.type === 'expandable'"
          class="pl-2` text-align-center"
          :class="!$lodash.isEmpty(hourData.labels) ? 'agenda-title-section-50' : 'agenda-title-section-65'"
        >
          <p class="agenda-hours-text">{{ hourData.expandable_details.title }}</p>
          <p class="agenda-hours-sub-text">
            {{ hourData.expandable_details.venue }} |
            {{ hourData.expandable_details.address }} |
            {{ hourData.expandable_details.phone_number }} |
            {{ hourData.expandable_details.website }}
          </p>
        </div>
        <div class="agenda-hours-last-item pr-1">
          <div v-if="hourData.static_details" :class="!$lodash.isEmpty(hourData, 'static_details.sponsors') && 'static-sponsors'">
            <img
              v-for="({ details, id }) in hourData.static_details.members.slice(0, (isMobileScreen?4:6))"
              :key="id"
              class="agenda-static-image mr-0.7 p-0"
              :src="details.prof_pic"
            />
          </div>
          <div v-else class="simple-event-info-btns mr-2">
            <i v-if="internalAgendaHours[index].show" class="fa fa-minus" />
            <i v-else class="fa fa-plus" />
          </div>
        </div>
      </div>
      <AgendaExpandableInfo
        v-if="internalAgendaHours[index].show && hourData.type === 'expandable'"
        :expandable-info="hourData.expandable_details"
      />
    </b-row>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import AgendaExpandableInfo from './AgendaExpandableInfo.vue';

export default {
  name: 'AgendaHours',
  components: {
    AgendaExpandableInfo,
  },
  data() {
    return {
      internalAgendaHours: [],
    };
  },
  async mounted() {
    if (this.$route.params.awardId) {
      const hours = await this.fetchAgenda(this.$route.params.awardId);
      this.internalAgendaHours = hours.sort(
        (a, b) => new Date(`1970/01/01 ${a.start_time}`)
            - new Date(`1970/01/01 ${b.start_time}`),
      );
    }
  },
  computed: {
    ...mapGetters({
      isMobileScreen: 'isMobileScreen',
    }),
  },
  methods: {
    ...mapActions({
      fetchAgenda: 'awards/fetchAgenda',
    }),
    moment(hour) {
      return moment(new Date(`1970/01/01 ${hour}`)).format('HH:mm');
    },
    showHourData(index) {
      const hours = this.$lodash.cloneDeep(this.internalAgendaHours);
      hours[index].show = !hours[index].show;
      this.internalAgendaHours = hours;
    },
  },
};
</script>

<style scoped>
.agenda-day-index {
  font-family: Montserrat;
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.5;
  letter-spacing: normal;
  text-align: left;
  color: #FFFFFF;
}
.agenda-day-row {
  background-color: #666666;
}
.agenda-day-date {
  font-family: Arial;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: normal;
  text-align: right;
  color: #FFFFFF;
}
.add-entry-btn,
.event-delete-btn {
  float: right;
}
.new-entry-row {
  border: solid 1px #DDDDDD;
}
.agenda-input-labels {
  font-family: HelveticaNeue;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #73818F;
  margin: 0;
}
.agenda-markers-icon {
  border-left: solid 1px #DDDDDD;
}
.map-longitude-latitude {
  margin-top: 15px;
  font-family: HelveticaNeue;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.09;
  letter-spacing: normal;
  text-align: left;
  color: #7F7F7F;
}
.add-to-map-titles {
  margin-top: 20px;
  font-family: Arial;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.09;
  letter-spacing: normal;
  text-align: left;
  color: red;
}
.save-video-btn {
  float: right;
}
.agenda-cration-selected {
  color: red;
}
.agenda-hours-col {
  border: 1px solid #DDDDDD;
}
.agenda-hours-box {
  background-color: white;
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 1;
  cursor: pointer;
  border-top: 1px solid #EBEBEB;
  border-bottom: 1px solid #EBEBEB;
  width: 100%;
}
.agenda-time-section {
  min-width: 59px;
  border-right: 1px solid #EBEBEB;
  padding: 2px 10px;
  display: flex;
  flex-direction: column;
}
.agenda-hours-text {
  font-family: Arial;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
  margin: 2px;
}
.agenda-hours-text,
.agenda-hours-sub-text {
  max-width: 300px;
  overflow: hidden;
}
.agenda-hours-sub-text, .agenda-hours-title-text {
  font-family: Arial;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
  margin: 2px;
}
.agenda-hours-sub-text{
  font-style: italic;
   color: #666666;
}
.agenda-hours-box .agenda-time-data {
  align-self: center;
}
.simple-agenda-edit-icon,
.simple-event-delete-icon {
  font-size: 15px;
  color: #FFFFFF;
  cursor: pointer;
  padding: 5px;
}
.simple-event-info-btns {
  color: #BD9D5F;
  background-color: white;
  font-size: 15px;
}
.modal-delete-text {
  font-family: Arial;
  text-align: center;
  font-size: 15px;
  color: #333333;
}
.static-sponsors {
  display: grid;
  grid-template-columns: 24px 24px 24px;
}
.agenda-static-image {
  width: 24px;
  height: 24px;
  object-fit: fill;
  grid-gap: 1px;
}
.agenda-hours-labels-div {
  height: 42px;
  overflow: scroll;
  scrollbar-width: none;
}
.agenda-hours-labels-div::-webkit-scrollbar {
  display: none;
}
.agenda-hours-labels {
  padding: 3px 4px;
  font-size: 11px;
  color: white;
  border: unset;
  border-radius: 3px;
  margin: 4px 4px 0 0;
}
.agenda-hours-last-item {
  margin-left: auto;
}

@media (max-width: 768px) {
  .agenda-hours-box {
    height: auto;
    padding: 7px 0;
    overflow: hidden;
  }
  .agenda-time-section {
    min-width: 80px;
    flex-direction: row;
    justify-content: space-around;
    font-size: 9px;
    padding: 2px;
    height: 48px;
  }
  .agenda-hours-labels-div {
    height: 48px;
  }
  .agenda-title-section-50 {
    max-width: 50%;
  }
  .agenda-title-section-65 {
    max-width: 65%;
  }
  .agenda-hours-labels {
    padding: 0 3px 0 0;
    font-size: 7px;
    background-color: #fff !important;
    color: #BD9D5F;
    border-radius: 0;
    margin-top: 0;
    border-right: 1px solid #bbb;
  }
  .static-sponsors {
    grid-template-columns: 24px 24px;
  }
  .agenda-hours-text,
  .agenda-hours-sub-text {
    font-size: 9px;
  }
}
</style>
