<template>
  <div class="agenda-expanded">
    <div class="cover-photo">
      <img :src="expandableInfo.banner" alt="" />
    </div>
    <div class="agenda-text-2">
      <h4 class="agenda-activity-title">{{ expandableInfo.title }}</h4>
      <p class="agenda-activity-address">{{ expandableInfo.address }}</p>
        <a :href="`tel:${ expandableInfo.phone_number}`" target="_self" class="agenda-link">
          <i class="fa fa-phone agenda-icon" aria-hidden="true" />
          {{ expandableInfo.phone_number }}
        </a>
      <a :href="expandableInfo.website" target="_blank" class="agenda-link">
        <i class="fa fa-globe agenda-icon" aria-hidden="true" /> Visit Website
      </a>
      <a :href="expandableInfo.gmap_link" target="_blank" class="">
        <button class="agenda-btn">Get directions</button>
      </a>
      <h4 class="agenda-activity-title mt-4">DRESSCODE</h4>
      <p class="agenda-activity-address">{{ expandableInfo.dresscode }}</p>
    </div>
    <div class="mt-2">
      <p class="sponsor-txt">sponsored by</p>
      <div class="agenda-activity-sponsors-cont">
        <img
          v-for="({ details }, index) in expandableInfo.sponsors"
          :key="index"
          class="sponsors-img mt-1 ml-1"
          :src="details.logo"
          :alt="details.name"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    expandableInfo: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style scoped>
.agenda-text-2 {
  width: 280px;
  font-size: 13px;
  line-height: 29px;
}
.cover-photo {
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: center;
}
.cover-photo > img {
  width: 200px;
  flex-basis: 500px;
  object-fit: cover;
}
.agenda-link{
  color: #BD9D5F;
  overflow: hidden;
  height: 20px;
  display: block;
  margin-bottom: 8px;
}
.agenda-expanded {
  padding: 10px;
  background-color: #f3f3f3;
  margin-left: 59px;
  width: calc(100% - 59px);
}
.agenda-btn {
  border: unset;
  color: #fff;
  background-color: #BD9D5F;
  display: block;
}
.agenda-icon {
  color: #BD9D5F;
}
.sponsors-img {
  width: 80px;
  height: 80px;
  object-fit: contain;
  border: 1px solid rgb(233, 233, 233);
  background: white;
}
.sponsor-txt {
  text-align: end;
  color: #767676;
  text-transform: capitalize;
  font-size: 13px;
  margin: 0;
}
.agenda-activity-address {
  font-family: Arial;
  font-size: 12px;
  line-height: 1.17;
  text-align: left;
  color: #333333;
}
.agenda-activity-title {
  margin-top: 10px;
  font-size: 18px;
  line-height: 1;
  text-align: left;
  color: #333333;
}
.agenda-activity-sponsors-cont {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
@media (max-width: 768px) {
  .agenda-btn {
    border-radius: 0 10px 10px 10px;
  }
  .agenda-expanded {
    margin: 0 auto;
    width: 90%;
  }
  .sponsors-img {
    width: 62px;
    height: 62px;
  }
}
</style>
