<template>
  <b-container fluid v-if="isActiveSection">
    <b-row class="agenda-section">
      <b-row no-gutters class="agenda-date-first-row" @click="showHours = !showHours">
        <div class="agenda-date-first-col">
          <p class="agenda-week-day">{{ agendaWeek(award.start_date) }}</p>
          <p class="agenda-day" v-if="!isMobileScreen">{{ agendaDay(award.start_date) }}</p>
          <p class="agenda-mounth" v-if="!isMobileScreen">{{ agendaMounth(award.start_date) }}</p>
          <p v-if="isMobileScreen" class="m-0 mt-0.7">{{ agendaDay(award.start_date) }} {{ agendaMounth(award.start_date) }}</p>
        </div>
        <div class="agenda-day-index" cols="10" />
        <div id="up-down">
          <div v-if="showHours">
            <i id="agenda-up-icon" class="fas fa-angle-up"></i>
          </div>
          <div v-else>
            <i id="agenda-up-icon" class="fas fa-angle-down"></i>
          </div>
        </div>
      </b-row>
      <AgendaHours v-if="showHours" />
    </b-row>
  </b-container>
  <div v-else style="text-align: center; color: #BD9D5F">The section is not available now and coming soon...</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import AgendaHours from './AgendaHours.vue';

export default {
  components: {
    AgendaHours,
  },
  data() {
    return {
      showHours: true,
    };
  },
  computed: {
    ...mapGetters({
      award: 'awards/award',
      isMobileScreen: 'isMobileScreen',
    }),
    isActiveSection() {
      return this.award && this.award.available_sections ? this.award.available_sections.includes('agenda') : false;
    },
  },
  async mounted() {
    if (this.$route.params.awardId) {
      this.agendaHours = await this.fetchAgenda(this.$route.params.awardId);
    }
  },
  methods: {
    ...mapActions({
      fetchAgenda: 'awards/fetchAgenda',
    }),
    agendaWeek(date) {
      return moment(date).format(`${this.isMobileScreen ? 'ddd' : 'dddd'}`, 'en');
    },
    agendaDay(date) {
      return moment(date).format('DD', 'en');
    },
    agendaMounth(date) {
      return moment(date).format('MMM', 'en');
    },
  },
};
</script>

<style>
.agenda-expanded > div > div {
  word-wrap: break-word;
}
</style>
<style scoped>
.agenda-date-first-row {
  background-color: #666666;
  color: #fff;
  border-radius: 0 10px 10px 10px;
  margin-bottom: 0px;
  cursor: pointer;
  margin-top: 8px;
  width: 100%;
  position: relative;
}
.agenda-date-first-row:hover {
  opacity: 0.5;
}
.agenda-date-first-col {
  border-right: 1px solid #ebebebeb;
  color: inherit;
  max-width: 60px;
  min-width: 60px;
  padding: 5px 0;
  text-align: center;
}
.agenda-day-index {
  color: inherit;
  font-size: 24px;
  text-transform: uppercase;
  padding-left: 10px;
  align-self: center;
}
.agenda-text {
  width: 380px;
  font-size: 13px;
  line-height: 29px;
}
.agenda-text-2 {
  width: 280px;
  font-size: 13px;
  line-height: 29px;
}
.agenda-section .container-fluid {
    margin-left: 60px;
    margin-right: 10px;
}

/* media screen mobile here */
@media (max-width: 768px) {
  .agenda-section .container-fluid {
    margin-left: 0;
    margin-right: 0;
  }
}

.second-col {
  max-width: 50px !important;
  background: white;
  border-right: 1px solid #e8e8e8;
  padding: 8px 6px 3px 5px;
  font-size: 11px;
}
.second-row {
  background-color: rgb(255, 255, 255);
  height: 50px;
  box-shadow: 0px 0px 1px 0px #5d5d5d;
  margin-bottom: -8px !important;
  border-bottom: 1px solid #f3f3f3;
  margin-top: -3px;
}
.btn-secondary:hover {
  opacity: 0.5;
}
.btn-secondary:focus {
  outline: none !important;
  box-shadow: unset !important;
}
.third-row {
  background-color: #f3f3f3;
  margin-left: 35px;
}
.cover-photo > img {
  width: 100%;
  height: 100px;
  object-fit: cover;
}
.agenda-expanded {
  padding: 14px 1px 9px 2px;
}
.agenda-btn {
  background: #BD9D5F;
  border: unset;
  color: white;
}
.sponsors-img {
  width: 100%;
  height: 80px;
  object-fit: contain;
}
.sponsors-col {
  border: 1px solid rgb(233, 233, 233);
  padding: 0;
  margin-left: 12px;
  padding: 0px 12px 1px 12px;
  background: white;
}
.sponsor-txt {
  margin: 5px 0px 6px 125px;
  color: #767676;
  text-transform: capitalize;
  font-size: 13px;
}
#agenda-icon {
  color: #BD9D5F;
}
#agenda-up-icon {
  color: white;
  margin: 12px -8px;
}
#up-down {
  position: absolute;
  top: 3px;
  right: 14px;
}
.topics > ul > li {
  font-size: 14px;
  font-style: italic;
}
.agenda-speaker-img {
  border: 1px solid #dfdfdfeb;
  max-width: 233px;
  margin: 0px 33px 19px 0px;
  height: 62px;
  padding: 0;
  padding-left: 15px;
  background: white;
}
.dg-cloud {
  background: #740000;
  color: white;
  font-size: 10px;
  padding: 0px 4px 0px 4px;
  border: unset;
  float: right;
  margin: 13px -30px;
  line-height: 19px;
}
.display {
  float: left;
  font-size: 12px;
  margin: 4px 6px 0px 0px;
  color: #555755;
}

.agenda-week-day {
  font-size: 9px;
  margin: 0 0 3px 0;
  line-height: 1;
}
.agenda-day {
  font-size: 12px;
  margin: 0;
  line-height: 1;
}
.agenda-mounth {
  font-size: 14px;
  margin: 0;
  line-height: 1;
}
@media (max-width: 768px) {
  .agenda-date-first-row {
    background-color: #fff;
    color: #333;
    margin-top: 0;
    height: 48px;
    align-items: center;
  }
  .agenda-section {
    border-radius: 0 10px 10px 10px;
    overflow: hidden;
  }
  #agenda-up-icon {
    color: #333;
  }
  .agenda-date-first-col {
    display: grid;
    grid-template-columns: 17px 63px;
    align-self: center;
    justify-self: center;
    padding: 0;
    border-right: unset;
    width: 79px;
    max-width: 80px;
  }
  .agenda-date-first-col>p {
    font-size: 14px;
  }
  .agenda-week-day {
    transform: rotate(270deg);
    text-transform: uppercase;
    border-bottom: 1px solid #ebebeb;
    width: 48px;
    margin: 2px 0px 0px -15px;
    line-height: 1.5;
    height: 17px;
    padding: 0px 0px 20px 0px;
  }
  .agenda-day,
  .agenda-mounth {
    margin: 2px -3px 0 0;
  }
  .agenda-day-index {
    border-left: 1px solid #ebebeb;
    padding: 6px 8px 0;
    font-size: 24px;
    height: 48px;
  }
}
</style>
